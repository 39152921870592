import { Component, OnInit } from '@angular/core';
import { WebapiService } from '../webapi.service'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  formData:any={};
  cData:any={};
  checkList: any;
  columnList: any;
  checkName: any;
  someSubscription: any;
  editcheckCounter: boolean =false;
  editFeildCounter: boolean = false;
  fData:any={};
  uploadExcelData: any={};

  constructor(private webapiservice:WebapiService,
    private router:Router,
    private activatedRoute: ActivatedRoute) {
      this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
      };
      this.someSubscription = this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          // Here is the dashing line comes in the picture.
          // You need to tell the router that, you didn't visit or load the page previously, so mark the navigated flag to false as below.
          this.router.navigated = false;
        }
      });
    }

  ngOnInit(): void {
    this.getAllCheck();
  }

  ngOnDestroy() {
    if (this.someSubscription) {
      this.someSubscription.unsubscribe();
    }
  }

  insertCheck(data:any){
  data.isActive = true;
  this.webapiservice.insertCheck(data).subscribe((res:any)=>{
    if(res.status == "ok"){
      alert(res.msg);
      this.formData = {};
      this.getAllCheck();
      }
      else{
       alert('Something went wrong');
      }

  });
  }

  getAllCheck(){
    this.webapiservice.getAllCheck().subscribe((res:any)=>{
     this.checkList = res.setting;
     console.log(this.checkList);

    });
  }

  getCheckById(id:any,name:any){
   this.cData._id = id;
   this.fData._id = id;
   this.checkName = name
   this.editcheckCounter =true;
   let index = this.checkList.findIndex(x=>x._id == id);
  if(index >=0){
    this.columnList = this.checkList[index].checkFeilds;
  }
  }
  deletetable(id:any){
   console.log(id);

  }

  insertColumn(data:any){
    if(!data._id){
      alert("Please select a Check to add feilds");
    }
    else{

      let index = this.checkList.findIndex(x=>x._id == data._id)
      if(index >=0){
       if(this.checkList[index].checkFeilds.length > 0){
         let getArr = this.checkList[index].checkFeilds;
        // getArr.push(data.field);
         getArr.push({
          "keyName":data.field
         });
         data.checkFeilds = getArr;
        }
        else{
           let arr = [];
           arr.push({
            "keyName":data.field
           });
           data.checkFeilds = arr;
        }
       //  console.log(data);
        this.webapiservice.insertCheck(data).subscribe((res:any)=>{
         // console.log(res);
        if(res.status == "ok"){
         alert(res.msg);
         this.cData = {};
         location.reload();
        }
        else{
         alert('Something went wrong');
        }
        });

      }

    }
  }

  editFeilds(){
    this.editFeildCounter =true;
  }

  closeeditFeilds(){
    this.editFeildCounter =false;
  }

  moveFeild(index:any,event:any){
    // console.log(feild,this.columnList);
    let indexToMove = index;
    let newIndex = 0;
    // Remove the element from the original position
    const removedElement = this.columnList.splice(indexToMove, 1)[0];
   if(event == "up"){
    newIndex = index -1
   }
   else{
     newIndex = index +1;
   }
  // Insert the element at the new position
     this.columnList.splice(newIndex, 0, removedElement);
  }
  deletefeild(feild:any){
    console.log(feild,this.columnList);

  }

  saveFeildData(){
     this.fData.checkFeilds = this.columnList;
     this.webapiservice.insertCheck(this.fData).subscribe((res:any)=>{
      if(res.status == "ok"){
        this.editFeildCounter =false;
        alert(res.msg);
       }
       else{
        alert('Something went wrong');
       }
     })

  }

  getUploadFile(e:any){
    const formData = new FormData();
    formData.append('image',e.target.files[0]);
    this.webapiservice.uploadImage(formData).subscribe((res: any) => {
       if(res.status == "ok"){
         this.uploadExcelData.file = res.imageName
         alert('file uploaded successfully');
        }
        else{
         alert("something went wrong")
        }
     });
  }

  uploadFile(data:any){
    this.webapiservice.uploadCheckExcel(data).subscribe((res: any) => {
      // console.log(res);
      if(res.status == "ok"){
        alert('Bulk Upload Success');
        location.reload();
      }
      else{
        alert('Upload Failed');
      }
    })
  }

}
