import { Component, OnInit } from '@angular/core';
import {Route, ActivatedRoute } from '@angular/router';
import { WebapiService } from '../webapi.service';

@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.scss']
})
export class InviteComponent implements OnInit {
  appId:any;
  formData:any={};
  constructor(private activatedRoute:ActivatedRoute,private WebApiService:WebapiService) { }

  ngOnInit(): void {
// this.activatedRoute.params.subscribe(params => {
//   console.log(params);
// });
  this.appId = this.activatedRoute.snapshot.paramMap.get('id');
  if(this.appId) {
    this.getApplicationdetail(this.appId);
  }
  }

  getApplicationdetail(id:any){
    this.WebApiService.getApplicationByIdInvite(id).subscribe((res:any)=>{
      // console.log(res);
        this.formData = res;
    });


  }

  insertApplication(data: any) {
  // console.log(data);
    this.WebApiService.insertApplication(data).subscribe((res: any) => {
      // console.log(res);
      if(res.status == "ok"){
        alert(res.msg);
        window.close();
      }
      else{
        alert(res.msg);
      }

    });

  }

  getChecksFile(e:any,index:any){
    const formData = new FormData();
    formData.append('image',e.target.files[0]);
    formData.append('type','return');
    this.WebApiService.uploadImage(formData).subscribe((res: any) => {
       if(res.status == "ok"){
         alert('upload success');
         this.formData.checksData[index].checkDoc = res.imageName;
        }
        else{
         alert("something went wrong")
        }
     });
    }

}
