import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChartsModule, ThemeService } from 'ng2-charts';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { FooterComponent } from './shared/footer/footer.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TodoComponent } from './apps/todo-list/todo/todo.component';
import { SpinnerComponent } from './shared/spinner/spinner.component';
import { ContentAnimateDirective } from './shared/directives/content-animate.directive';
import { TodoListComponent } from './apps/todo-list/todo-list.component';
import { EmployeeComponent } from './employee/employee.component';
import { TeamComponent } from './team/team.component';
import { SettingsComponent } from './settings/settings.component';
import { ProfileComponent } from './profile/profile.component';
import { ApplicationComponent } from './application/application.component';
import { ChecksComponent } from './checks/checks.component';
import { ExportComponent } from './export/export.component';
import { NewApplicationComponent } from './new-application/new-application.component';
import { BulkApplicationComponent } from './bulk-application/bulk-application.component';
import { ClientComponent } from './client/client.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { InviteComponent } from './invite/invite.component';
import { ReportComponent } from './report/report.component';
import { DatePipe } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    SidebarComponent,
    FooterComponent,
    DashboardComponent,
    TodoListComponent,
    TodoComponent,
    SpinnerComponent,
    ContentAnimateDirective,
    EmployeeComponent,
    TeamComponent,
    SettingsComponent,
    ProfileComponent,
    ApplicationComponent,
    ChecksComponent,
    ExportComponent,
    NewApplicationComponent,
    BulkApplicationComponent,
    ClientComponent,
    InviteComponent,
    ReportComponent,
    
  
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    ChartsModule,
    HttpClientModule,
    NgxPaginationModule,
    // DatePipe
  ],
  providers: [ThemeService],
  bootstrap: [AppComponent]
})
export class AppModule { }
